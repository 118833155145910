import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import { FooterI18n } from './footer'
import { FeaturesI18n } from './features'
import { ServiceI18n } from './service'

i18n.use(initReactI18next).init({
	resources: {
		zh: {
			translation: {
				metaTitle: '链上会4.0 - 基于数信云的分布式商业应用终端',
				metaKeyWords: '链上会，隐私安全，会议，通信，加密，加密会议，轻应用',
				metaDescription:
					'基于区块链分布式账本、分布式公钥基础设施（DPKI）、分布式数字身份（DID）等技术实现“协同、分享、建设”为核心的功能矩阵，打造保障隐私安全的Web3入口，提供一站式 Web3 DAO Kit。',
				appName: '链上会',
				'focus-title': '协同 分享 建设',
				'focus-content':
					'基于区块链分布式账本、分布式公钥基础设施（DPKI）、分布式数字身份（DID）等技术实现“协同、分享、建设”为核心的功能矩阵，打造保障隐私安全的Web3入口，提供一站式 Web3 DAO Kit。',
				'nav-1': '解决方案',
				'nav-2': '功能介绍',
				// 'nav-4': '开发接入',
				'nav-3': '服务升级',
				'nav-4': '关于我们',
				'nav-5': '下载中心',
				'nav-6': '数信云',
				'download-title': '下载',
				'download-guide': '安装指引->',
				'download-tip': '扫描二维码下载<br/>手机客户端',
				'download-report': 'App尽职调查报告',
				...FooterI18n.zh,
				...FeaturesI18n.zh,
				...ServiceI18n.zh,
			},
		},
		en: {
			translation: {
				metaTitle: 'ChainPal - Distributed Business Application Terminal based on D-Cloud',
				metaKeyWords:
					'ChainMeet, ChainPal, Privacy Security, Conference, Communication，Encrypted Meeting，Light App, Web3',
				metaDescription:
					'Based on distributed ledger, decentralized public key infrastructure (DPKI), decentralized identity (DID), ChainPal provides a functional matrix of "Coordinate, Share, and Build" , creating a privacy-preserving Web3 interface, providing One-stop Web3 DAO Kit.',
				appName: 'ChainPal',
				'focus-title': 'Collaboration · Share · Build',
				'focus-content':
					'Based on distributed ledger, decentralized public key infrastructure (DPKI), decentralized identity (DID), ChainPal provides a functional matrix of "Coordinate, Share, and Build" , creating a privacy-preserving Web3 interface, providing One-stop Web3 DAO Kit.',
				'nav-1': 'Solution',
				'nav-2': 'Features',
				'nav-3': 'Service',
				'nav-4': 'About us',
				'nav-5': 'Download',
				'nav-6': 'D-Cloud',
				'download-title': 'Download',
				'download-guide': 'Guidelines->',
				'download-tip': 'Scan the QR code to download  the mobile client',
				'download-report': 'APP Due Diligence Report',
				...FooterI18n.en,
				...FeaturesI18n.en,
				...ServiceI18n.en,
			},
		},
	},
	lng: 'zh',
	fallbackLng: 'zh',
	interpolation: {
		escapeValue: false,
	},
})

export default i18n
