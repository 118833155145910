export const FeaturesI18n = {
	zh: {
		'features-title-1': 'Serve Builders,<b>Serve DAO</b>',
		'features-title-1-0': 'Serve Builders,<br><b>Serve DAO</b>',
		'features-subtitle-1': '协同',
		'features-subtitle-2': '分享',
		'features-subtitle-3': '建设',
		'features-detail-1':
			'基于点对点通信技术的加密通信与音视频会议功能，服务系统不对任何聊天数据进行存储，实现区块链加密、防破解、防拦截。',
		'features-detail-2':
			'基于IPFS为用户提供便捷、安全、隐私保护的文件管理系统，数据主权100%用户自有，提供基于区块链的数据共享与数据价值挖掘。',
		'features-detail-3':
			'提供以身份聚合为核心的DID解决方案，灵活、可自定义的插件功能；提供DID、NFT铸造、DAO协同等Web3应用建设工具。',
		'features-title-2-1': '<em>技术</em>特性',
		'features-safe-1':
			'<em>DID</em><br>Decentralized Identity',
		'features-description-1':
			'分布式身份标识，基于分布式公钥基础设施（DPKI）提供数字身份认证标识',
		'features-safe-2':
			'<em>VC</em><br>Verifiable Claim',
		'features-description-2':
			'可验证声明支撑凭证类应用，支撑包括数字身份认证、资质凭证认证、数据验证、数字藏品发行、物联设备验证等凭证类应用场景',
		'features-safe-3':
			'<em>DC</em><br>Data Container',
		'features-description-3':
			'自主主权数据容器，使用本地SDK实现私钥本地化存储管理和使用，使用IPFS （分布式文件存储系统）实现图片、文件等个人数据的自主管控存储',
		'features-safe-4':
			'<em>云链一体化，</em>一体化加密轻应用入口；一键授权登录轻应用',
		'features-did-title1': '<em>数据</em>托管',
		'features-web3-title1': '开放平台',
		'message-did-1': '自主主权身份',
		'message-did-2': '多场景凭证应用',
		'message-did-3': '可恢复数据容器',
		'did-detail-1':
			'通过DPKI实现私有数据自主掌握，创造自主主权数字身份，身份聚合，多链兼容。',
		'did-detail-2':
			'装载多种类型身份凭证，支撑DAO成员身份验证、资质验证等多种应用场景。',
		'did-detail-3':
			'基于IPFS提供个人自主掌控的数据容器，打造实现数据安全恢复，保护数据隐私，协助数据共享的链上文档功能。',
		'meet-web3-1': '便捷Web3入口',
		'meet-web3-2': 'Web3跨域专网',
		'meet-web3-3': 'Web3应用排行榜',
		'meet-web3Detail-1':
			'汇集全网热门Web3应用，提供便捷访问入口。',
		'meet-web3Detail-2':
			'超级会员专享Web3跨域专网，无缝链接Web3生态。',
		'meet-web3Detail-3':
			'提供热门Web3应用排行榜，自建Web3应用也可参与排行。',
		'features-message-title1': '即时通讯',
		'features-message-title2': '（密信）',
		'features-meet-title1': '链上',
		'features-meet-title2': '协同',
		'features-web-title': '轻应用',
		'message-nav-1': '阅后即焚',
		'message-nav-2': '多侧删除',
		'message-nav-3': '匿名群聊',
		'message-nav-4': '临时群聊',
		'message-detail-1':
			'聊天设置为阅后即焚，则发出的消息不会直接展示在聊天页面，对方点击后方可查看，查看后返回聊天页面立即销毁。',
		'message-detail-2':
			'删除某条或者整体聊天记录时，可选择多侧删除。多侧删除后，您与他人都无法在看到该条聊天记录。',
		'message-detail-3':
			'匿名群聊内，所有成员不展示真实昵称与头像，不可查看个人名片，系统随机为成员分配名称与头像，无法得知群内人员真实身份。',
		'message-detail-4':
			'临时群聊自创建时间算起，24h后自动消失，且各方记录随之销毁。',
		'meet-nav-1': '周期会议',
		'meet-nav-2': '指定参会成员',
		'meet-nav-3': '参会信息导出',
		'meet-nav-4': '插件管理',
		'meet-detail-1':
			'预定会议时，可将会议设置为周期会议，频率以每天/每个工作日/每周/每月为基准，按时开启会议。',
		'meet-detail-2':
			'创建会议时，可指定参会成员，设置成功后，非指定成员无权限入会。',
		'meet-detail-3':
			'主持人在管理页面可导出参会信息，包含会议主体信息与每位参会成员详细进出信息、时间记录等。',
		'meet-detail-4': '提供插件管理列表，便捷高效访问。',
		'web3-subtitle': '官方推荐轻应用',
		'web3-title-1': 'D凭证',
		'web3-title-2': 'D市场',
		'web3-title-3': 'D签约',
		'web3-title-4': 'D查查',
		'web3-content-1':
			'为您提供成熟的分布式身份认证解决方案，提供可直接使用的云端身份认证应用、底层证明链和模块化软件生态，实现云原生、可跨链的分布式数字身份认证应用。',
		'web3-content-2':
			'D市场是国内合规的App市场数据和App分发平台。D市场向开发者和用户提供便利的应用安全服务及应用市场数据追踪服务，包括基于Web3的应用数据信息。',
		'web3-content-3':
			'D签约是全生命周期、一站式智能合同服务。为您提供成熟的链上合同管理解决方案，提供基于可靠电子签名和区块链存证的电子合同签署和管理服务。',
		'web3-content-4':
			'一站式链上分析平台，提供全网加密资产风险监控及在线分析服务，覆盖10亿+标签数据查询及DeFi、NFT、OTC等业态，全面保障Web3生态安全。',
		'coordination1': 'Web3 ',
		'coordination2': 'DAO ',
		'coordination3': 'Kit',
		'coordination-title1': '快速高效准入功能',
		'coordination-title1-detail1': '通过区块链技术对DAO成员进行准入验证以及身份凭证核验',
		'coordination-title1-detail2': 'DAO建设者通过去中心化的方式为DAO成员提供NFT/SBT形式身份凭证',
		'coordination-title2': '协同平台',
		'coordination-title2-detail1': '链上群组音视频讨论会议',
		'coordination-title2-detail2': '解决信息泄露，沟通低效问题',
		'coordination-title3': '薪酬支付',
		'coordination-title3-detail1': '基于智能合约提供全新Web3薪酬支付解决方案',
		'coordination-title3-detail2': '集成去中心化支付协议，便捷高效、公开透明的支付解决方案',
		'coordination-title4': '公平便捷治理流程',
		'coordination-title4-detail1': '基于DID与凭证技术实现高效公平的提案与投票功能',
		'coordination-title4-detail2': '实现“一人一票”，提高女巫攻击门槛',
		'coordination-title4-detail3': '为DAO建设者节省管理成本，完成更有意义的功能建设',
		'coordination-title5': 'DAO数字营销',
		'coordination-title5-detail1': '随时随地开展链上音视频会议，创建社区活动',
		'coordination-title5-detail2': '链上会便捷会议管理功能，大幅提升获客效率',
		'coordination-title5-detail3': 'DAO组织自主搭建Web3应用，为目标客户精准推送相关内容消息',
		'coordination-title5-detail4': '支持NFT/SBT 铸造，为DAO组织提供具备收藏与稀缺性的会员徽章元素的数字营销活动',
		'enterprise-title': '任务<em>协同</em>工具',
		'enterprise-content': '企业办公过程中经常涉及大量商业秘密和隐私数据，传统协同办公工具面临通信信道被监听、机密数据易泄露、内部通讯管理难等问题。链上会提供本地化部署方案，为企业机构用户提供一站式安全、隐私保护、加密协同办公解决方案。包括：',
		'enterprise-item1-mid': '本地化安全节点',
		'enterprise-item1-bot': '支持会议服务本地化部署，通信不出内网环境，全量数据本地存储，全链路数据区块链加密，防监听防泄漏。',
		'enterprise-item2-mid': '私有化文件存储系统',
		'enterprise-item2-bot': '通过部署私有化IPFS（星际文件存储系统）节点实现企业文件安全存储，大幅度提升数据存储安全。',
		'enterprise-item3-mid': '企业级节点防护解决方案',
		'enterprise-item3-bot': '搭载通付盾企业级WAAP（Web Application and API Protection）防护解决方案，实现Web应用防护、API防护与DDoS攻击防护等功能，全方位保护区块链私有节点免受外部攻击。'
	},
	en: {
		'features-title-1':
			'Serve Builders,<b>Serve DAO</b>',
		'features-title-1-0':
			'Serve Builders,<b>Serve DAO</b>',
		'features-subtitle-1': 'Coordinate',
		'features-subtitle-2': 'Share',
		'features-subtitle-3': 'Build',
		'features-detail-1':
			'Provide encrypted communication and audio/video conference functions based on peer-to-peer communication, the service system does not store any chat data. Blockchain encrypted, anti-cracking, and anti-interception.',
		'features-detail-2':
			'Based on IPFS, provide users with a convenient, safe, and privacy-preserving file management system. Data is 100% user-owned. Provide blockchain-based data sharing thereby optimizes data value.',
		'features-detail-3':
			'Provide DID solutions with identity aggregation as the core, flexible and customizable plug-in functions; provide Web3 application construction tools such as DID, NFT casting, and DAO collaboration.',
		'features-title-2-1': '<em>Technology</em> Features',
		'features-safe-1':
			'<em>DID</em><br>Decentralized Identity',
		'features-description-1':
			'Create decentralized identifiers, based on decentralized public key infrastructure (DPKI) to provide digital identity authentication.',
		'features-safe-2':
			'<em>VC</em><br>Verifiable Claim',
		'features-description-2':
			'Create verifiable claims for credential applications, and support scenarios including digital identity authentication, qualification certificate authentication, data verification, NFT minting, and IoT device verification.',
		'features-safe-3':
			'<em>DC</em><br>Data Container',
		'features-description-3':
			'Self-sovereign data container, using local SDK to realize localized storage management and the use of private keys, using IPFS (distributed file storage system) to realize self-controlled storage of personal data such as pictures and files.',
		'features-safe-4':
			'<em>D-Cloud Chain collaborative integration encryption,</em> light application entrance; one-click authorization to log in to the light application',
		'features-did-title1': '<em>Data</em> Hosting',
		'message-did-1': 'Self-sovereign Identity',
		'message-did-2': 'Multi-scene Credential Application',
		'message-did-3': 'Recoverable Data Container',
		'did-detail-1': 'Realize independent control of private data through DPKI, fully achieve self-sovereign identity, provide identities aggregation, and multi-chain compatibility.',
		'did-detail-2': 'Loaded with various types of identity certificates, supporting various application scenarios such as DAO member identity verification and qualification verification.',
		'did-detail-3': 'Based on IPFS, it provides a data container under personal control, creating an on-chain document function that realizes safe data recovery, protects data privacy, and assists in data sharing.',
		'meet-web3-1': 'Convenient Web3 Interface',
		'meet-web3-2': 'Web3 Cross-domain Private Network',
		'meet-web3-3': 'Web3 Application Ranking',
		'meet-web3Detail-1':
			'Gather popular Web3 applications on the entire network and provide convenient access portals.',
		'meet-web3Detail-2':
			'Super Members can enjoy Web3 cross-domain private network, provides seamlessly linking with Web3 ecosystem.',
		'meet-web3Detail-3':
			'Provide a ranking list of popular Web3 applications, and self-built Web3 applications can also participate in the ranking.',
		'features-web3-title1': 'Open Platform',
		'features-message-title1': 'Instant Messaging ',
		'features-message-title2': '(DChat)',
		'features-meet-title1': 'DAO ',
		'features-meet-title2': 'Kit',
		'features-web-title': 'Web3',
		'message-nav-1': 'Boltchat',
		'message-nav-2': 'Deletion on multiple sides',
		'message-nav-3': 'Anonymous group chat',
		'message-nav-4': 'Temporary group chat',
		'message-detail-1':
			'If the chat is set to Boltchat, the sent messages will not be displayed directly on the chat page. The other party can view it after clicking it. After viewing, return to the chat page and destroy it immediately.',
		'message-detail-2':
			'When deleting one or the entire chat history, you can choose to delete multiple sides. After multiple sides are deleted, neither you nor the other party can see the chat history.',
		'message-detail-3':
			'In the anonymous group chat, all members do not display their real nicknames and avatars, and they cannot view their personal business cards. The system randomly assigns names and avatars to members, and it is impossible to know the real identities of people in the group.',
		'message-detail-4':
			'Temporary group chats will automatically disappear after 24 hours from the time of creation, and the records of all parties will be destroyed.',
		'meet-nav-1': 'Periodic Meeting',
		'meet-nav-2': 'Private Meeting',
		'meet-nav-3': 'Export Attendees',
		'meet-nav-4': 'Plug-in Management',
		'meet-detail-1':
			'When booking a meeting, you can set the meeting as a recurring meeting. The frequency is based on every day/every working day/week/month, and the meeting will start on time.',
		'meet-detail-2':
			'When creating a conference, you can create a private meeting. After the setting is successful, non-specified members have no right to join the conference.',
		'meet-detail-3':
			'The host can export attendees on the management page, including the meeting subject information and the detailed entry and exit information of each participant, time records, etc.',
		'meet-detail-4': 'Provides a plug-in management list for convenient and efficient access.',
		'web3-subtitle': 'Official Recommended Apps',
		'web3-title-1': 'IdentityOmni',
		'web3-title-2': 'DAppStore',
		'web3-title-3': 'DSign',
		'web3-title-4': 'ChainAegis',
		'web3-content-1':
			'IdentityOmni provides you with a perfect distributed identity authentication solution, providing directly-usable cloud identity authentication applications, underlying proof chains and modular software ecology to realize cloud-based, cross-chain digital identity application.',
		'web3-content-2':
			'DStore is a domestic compliant App market data and App distribution platform. Application market data tracking services, including Web3-based application data information, are conveniently offered by DStore to developers and users.',
		'web3-content-3':
			'DSign is a one-stop intelligent contract service for the whole life cycle. Provide you with a mature on-chain contract management solution, and provide electronic contract signing and management services based on reliable electronic signatures and blockchain certificates.',
		'web3-content-4':
			'One-stop on-chain analysis platform that provides risk monitoring and online analysis services of the entire crypto network, covering 1 billion+ tag data and multiple catagories including DeFi, NFT and OTC. Dedicated protector of Web3 security.',
		'coordination1': 'Web3 ',
		'coordination2': 'DAO ',
		'coordination3': 'Kit',
		'coordination-title1': 'Fast and efficient access control',
		'coordination-title1-detail1': 'Access verification and identity verification of DAO members through blockchain technology.',
		'coordination-title1-detail2': 'DAO builders provide DAO members with NFT/SBT identity certificates in a decentralized way.',
		'coordination-title2': 'Collaboration platform',
		'coordination-title2-detail1': 'On-chain group audio and video discussion conference.',
		'coordination-title2-detail2': 'Solve the problem of information leakage and inefficient communication.',
		'coordination-title3': 'Salary pay',
		'coordination-title3-detail1': 'Provide a new Web3 salary payment solution based on smart contracts.',
		'coordination-title3-detail2': 'Provide convenient, efficient, open and transparent payment solution based on integrated decentralized payment protocol.',
		'coordination-title4': 'Fair and convenient governance process',
		'coordination-title4-detail1': 'Efficient and fair proposal and voting functions based on DID and credential technology.',
		'coordination-title4-detail2': 'Realize "one person, one vote" and raise the threshold for witch attacks.',
		'coordination-title4-detail3': 'Save management costs for DAO builders and complete more meaningful function construction.',
		'coordination-title5': 'DAO digital marketing',
		'coordination-title5-detail1': 'Convene audio/video conferences on-chain and organize community activities anytime and anywhere.',
		'coordination-title5-detail2': 'The convenient conference management function of ChainPal greatly improve the efficiency of customer acquisition.',
		'coordination-title5-detail3': 'The DAO organization independently builds Web3 applications, accurately push relevant content information for target customers.',
		'coordination-title5-detail4': 'Support NFT/SBT casting, and provide DAO organizations with digital marketing activities involving collectible and scarce member badge elements.',
		'enterprise-title': ' Task <em> Collaboration </em> Tool',
		'enterprise-content': 'A large amount of business secret and private data are often involved in the process of corporate office work. Traditional collaborative office tools face problems such as communication channels being monitored, confidential data easily leaked, and internal communication management difficulty. ChainPal provides localized deployment solutions and provides one-stop security, privacy protection and encrypted collaborative office solutions for corporate users. Including:',
		'enterprise-item1-mid': 'Secure nodes localization',
		'enterprise-item1-bot': 'ChainPal supports localized deployment of conference services. All communications occur in the intranet environment. Full data stored locally and all link-road data encrypted through blockchain to achieve anti-monitoring and anti-leakage.',
		'enterprise-item2-mid': 'File storage system privatization',
		'enterprise-item2-bot': 'By deploying privatized IPFS (Interplanetary File Storage System) nodes to achieve secure storage of enterprise files, the security of data storage is greatly improved.',
		'enterprise-item3-mid': 'Enterprise-level node protection solution',
		'enterprise-item3-bot': 'Equipped with PayEgis enterprise-level WAAP (Web Application and API Protection) protection solution, it implements functions such as Web application protection, API protection and DDoS attack protection, and comprehensively protects blockchain private nodes from external attacks.'
	},
}
