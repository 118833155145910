/* 下载中心 */

import React, { useState, useCallback } from 'react'
import styles from './index.module.css'
import qrEn from '../../images/qr-en.png'
import qrZh from '../../images/qr-zh.png'
import qrIos from '../../images/ios-qr.png'
import qrAndroid from '../../images/android-qr.png'
import QRBg from '../../images/QR-bg.png'
import ios from '../../images/ios.png'
import windowImg from '../../images/window.png'
import android from '../../images/android.png'
import jump from '../../images/jump.png'
import '../../i18n'
import { useTranslation } from 'react-i18next'

import { CSSTransition } from 'react-transition-group'

import { isApp, getUAType } from '../../util'
import { Toast } from 'antd-mobile'

function Download({ downloadRefInView, currentLanguage }) {
	const { t } = useTranslation()
	const [list] = useState([
		{
			name: 'iOS',
			img: ios,
		},
		{
			name: 'Android',
			img: android,
		},
		{
			name: 'macOS',
			img: ios,
		},
		{
			name: 'Windows',
			img: windowImg,
		},
	])

	const handler = useCallback(
		index => {
			console.log(index, 'index')
			console.log(isApp(), '000')
			let urlObj = {
				0: 'https://apps.apple.com/cn/app/%E9%93%BE%E4%B8%8A%E4%BC%9A-%E6%97%97%E8%88%B0%E7%89%88/id1635723004',
				1: 'https://chat-minio.tongfudun.com/android-release/ChainMeeting.apk',
				2: 'https://chat-minio.tongfudun.com/release/%E9%93%BE%E4%B8%8A%E4%BC%9A-mac.dmg',
				3: 'https://chat-minio.tongfudun.com/release/%E9%93%BE%E4%B8%8A%E4%BC%9A-win-setup.exe',
			}
			if (index === 1 && !isApp()) {
				window.open(urlObj[index])
			} else if (index === 2 && isApp() && (getUAType() === 'm' || getUAType() === 'ipad')) {
				// 请移步到官网下载！ Please go to the official website to download ！
				Toast.show({
					content:
						currentLanguage === 'en'
							? 'Please go to the official website to download ！'
							: '请移步到官网下载！',
					mask: true,
					duration: 2500,
				})
			} else if (index === 3 && isApp() && (getUAType() === 'm' || getUAType() === 'ipad')) {
				Toast.show({
					content:
						currentLanguage === 'en'
							? 'Please go to the official website to download ！'
							: '请移步到官网下载！',
					mask: true,
					duration: 2500,
				})
			} else {
				window.location.href = urlObj[index]
			}
		},
		[currentLanguage]
	)

	return (
		<div className={styles['download']}>
			<CSSTransition in={downloadRefInView} timeout={1000} classNames="fade-bottom">
				<h3>
					<strong className={`public-title ${currentLanguage}`}>{t('download-title')}</strong>
				</h3>
			</CSSTransition>
			<CSSTransition in={downloadRefInView} timeout={1000} classNames="fade-bottom">
				<div className={styles['content']}>
					<div className={styles['QR-wrap']}>
						<div className={styles['QR']}>
							<figure>
								<img src={currentLanguage === 'en' ? qrEn : qrZh} alt="" />
							</figure>
							<p dangerouslySetInnerHTML={{ __html: t('download-tip') }}></p>
						</div>
						<img src={QRBg} alt="" className={styles['QR-bg']} />
					</div>
					<ul>
						{list.map((item, index) => {
							return (
								<li key={index} data-index={`dom-${index}`}>
									<div
										className={styles['card']}
										onClick={() => {
											handler(index)
										}}
									>
										<figure data-index={`dom-${index}`}>
											{[0, 1].includes(index) ? (
												<img
													className={styles['hover-qr-img']}
													src={index === 0 ? qrIos : qrAndroid}
													alt=""
												/>
											) : (
												''
											)}
											<img src={item.img} alt="" className={styles['qr-img']} />
										</figure>
										<strong data-index={`dom-${index}`}>{item.name}</strong>
									</div>
									{item.name === 'macOS' && (
										<a
											href="https://support.apple.com/zh-cn/HT202491"
											target="_blank"
											className={styles['guide']}
											rel="noreferrer"
										>
											{t('download-guide')}
										</a>
									)}
								</li>
							)
						})}
					</ul>
					<div className={styles['down-report']}>
						<a href="https://dappstore.tongfudun.com/api/app_market/app-report/investigate4chain">
							<p>{t('download-report')}</p>
							<img src={jump} alt="" />
						</a>
					</div>
				</div>
			</CSSTransition>
		</div>
	)
}
export default Download
