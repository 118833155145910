import React, { useState } from 'react'

import '../../i18n'
import { useTranslation } from 'react-i18next'
import logoEn from '../../images/logo-en.png'
import logoZh from '../../images/logo-zh.png'

import { eventBus } from '../../util'

import './index.css'
function Footer({ currentLanguage }) {
	const { t } = useTranslation()
	const [list] = useState([
		[1, 2],
		[3, 1, 2],
		[1, 2, 3, 4],
	])
	const [hrefList] = useState([
		'https://www.tongfudun.com',
		'info@tongfudun.com',
		'service@tongfudun.com',
		'400-831-8116',
	])

	const handleGo = e => {
		let indexStr = e.target.getAttribute('data-index')
		if (!indexStr) return
		let father = Number(indexStr.split('-')[0])
		let children = Number(indexStr.split('-')[1])
		console.log(father, children)
		switch (father) {
			case 0:
				let domRef1 = {
					1: 'solution',
					2: 'features',
				}
				let solutionDom = document.getElementById(domRef1[children])
				if (solutionDom) {
					solutionDom.scrollIntoView({ block: 'start', behavior: 'smooth' })
				}
				eventBus.emit('go2Solution', children - 1)
				break
			case 1:
				let domRef = {
					1: 'Did',
					2: 'Web3',
					3: 'Meeting',
				}
				let anchorElement = document.getElementById(domRef[children])
				if (anchorElement) {
					anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' })
				}
				// 开发平台
				if (children === 2) {
					let openPlatform =
						window.location.host.indexOf('tongfudun.com') > -1
							? 'https://open-chain.tongfudun.com'
							: 'http://imdev.arraypay.cn:6790'
					window.open(openPlatform)
				}
				break
			case 2:
				if (children === 1) {
					window.open(hrefList[children - 1])
				} else if (children === 4) {
					window.location.href = `tel:${hrefList[children - 1]}`
				} else {
					window.location.href = `mailto:${hrefList[children - 1]}`
				}
				break
			default:
				console.log('其他')
				break
		}
	}

	return (
		<footer className="footer-wrap">
			<div className="footer-content">
				<h2>
					<img src={currentLanguage === 'en' ? logoEn : logoZh} alt="" />
				</h2>
				<ul
					onClick={e => {
						handleGo(e)
					}}
				>
					{list.map((item, index) => {
						return (
							<li key={index}>
								<h5>{t(`footer-title-${index + 1}`)}</h5>
								{item.map(el => {
									return (
										<p key={el}>
											<span data-index={`${index}-${el}`}>{t(`footer-${index + 1}-${el}`)}</span>
											{index === 2 && <span data-index={`${index}-${el}`}>{hrefList[el - 1]}</span>}
										</p>
									)
								})}
							</li>
						)
					})}
				</ul>
			</div>
			<div className="copyright">{t('copyright')}</div>
		</footer>
	)
}
export default Footer
