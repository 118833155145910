export const ServiceI18n = {
	zh: {
		'service-title': '服务<em>升级</em>',
		'service-vip-0': '免费用户',
		'service-vip-1': '高级会员',
		'service-vip-2': '超级会员',
		'service-vip-3': '专业会员',
		'service-vip-unit': '月',
		'service-vip-tip': '免费版权益',
		'service-vip-tip-1': '包含「免费版」所有权益以及',
		'service-vip-tip-2': '请移步移动端购买服务',
		'service-vip-tip-3': '附赠100张会员券',
		'service-vip-tip-4': '仅限海外版ChainPal App',
		'service-list-0': '存储',
		'service-list-1': '协同',
		'service-list-2': 'Web3',
		'service-list-0-1-1': '<em>1GB</em> IPFS 分布式存储空间',
		'service-list-0-1-2': 'IPFS 名片管理',
		'service-list-0-2-0': '会议时长 <i>60分钟</i>',
		'service-list-0-2-1': '会议创建次数 <i>不限</i>',
		'service-list-0-2-2': '最高入会方数 <i>100</i>',
		'service-list-0-2-3': '状态更新',
		'service-list-0-2-4': '智能合同',
		'service-list-0-2-5': '伙伴成功',
		'service-list-1-1-1': '<em>3GB</em> IPFS 分布式存储空间',
		'service-list-1-2-0': '会议时长 <i>不限</i>',
		'service-list-1-2-1': '会议创建次数 <i>不限</i>',
		'service-list-1-2-2': '最高入会方数 <i>100</i>',
		'service-list-1-2-3': '指定参会成员',
		'service-list-2-1-1': '<em>5GB</em> IPFS 分布式存储空间',
		'service-list-2-2-2': '最高入会方数 <i>150</i>',
		'service-list-2-3-1': '跨域专网',
		'service-list-2-3-2': '智能账户',
		'service-list-2-3-3': '链上数据分析与风险预警',
		'service-list-2-3-4': '链上结构化产品信息服务',
		'service-list-3-2-2': '最高入会方数 <i>300</i>',
		'service-list-3-3-5': '节点扩展',
		'service-list-3-3-6': '开放平台',

	},
	en: {
		'service-title': 'Service <em>Upgrade</em> ',
		'service-vip-0': 'Free',
		'service-vip-1': 'Premium',
		'service-vip-2': 'Super',
		'service-vip-3': 'Professional',
		'service-vip-unit': 'month',
		'service-vip-tip': 'Free Edition Benefits',
		'service-vip-tip-1': 'Includes all benefits of the Free Edition plus',
		'service-vip-tip-2': 'Please move to the mobile terminal to purchase services',
		'service-vip-tip-3': 'Comes with 100 membership coupons',
		'service-vip-tip-4': 'Overseas version of ChainPal App only',
		'service-list-0': 'ChainDoc',
		'service-list-1': 'Collaborative',
		'service-list-2': 'Web3',
		'service-list-0-1-1': '<em>1GB</em> of IPFS Distributed Storage',
		'service-list-0-1-2': 'IPFS Business Card Management',
		'service-list-0-2-0': 'Meeting length <i>60 minutes</i>',
		'service-list-0-2-1': '<em>Unlimited</em> meeting creation',
		'service-list-0-2-2': '<em>100</em> max attendees',
		'service-list-0-2-3': 'Status Update',
		'service-list-0-2-4': 'Smart Contract',
		'service-list-0-2-5': 'Partner Success',
		'service-list-1-1-1': '<em>3GB</em> of IPFS Distributed Storage',
		'service-list-1-2-0': '<em>Unlimited</em> meeting length',
		'service-list-1-2-1': '<em>Unlimited</em> meeting creation',
		'service-list-1-2-2': '<em>100</em> max attendees',
		'service-list-1-2-3': 'Private Meeting',
		'service-list-2-1-1': '<em>5GB</em> of IPFS Distributed Storage',
		'service-list-2-2-2': '<em>150</em> max attendees',
		'service-list-2-3-1': 'CDNM',
		'service-list-2-3-2': 'Smart Account',
		'service-list-2-3-3': 'On-Chain Data Analysis and Risk Warning',
		'service-list-2-3-4': 'On-Chain Structured Product Trading Platform',
		'service-list-3-2-2': '<em>300</em> max attendees',
		'service-list-3-3-5': 'Node Expansion',
		'service-list-3-3-6': 'Open Platform',
	},
}
