import React, { useState, useEffect, useMemo } from 'react'
import './index.css'
import { isApp } from '../../util'
import '../../i18n'
import { useTranslation } from 'react-i18next'
import logoZh from '../../images/logo-zh.png'
import logoEn from '../../images/logo-en.png'
import zhIcon from '../../images/zh.png'
import enIcon from '../../images/en.png'

function Header({ props }) {
	let { scrollRef, currentLanguage, handlerChange, handleShowAction } = props
	const { t } = useTranslation()
	const [currentDom, setCurrentDom] = useState(-1)

	const navList = useMemo(() => {
		return [
			{ ref: 'focus' }, // 焦点图 => 置顶
			{ name: t('nav-1'), ref: 'solution' }, // 解决方案
			{ name: t('nav-2'), ref: 'features' }, // 功能介绍
			{ name: t('nav-3'), ref: 'service' }, // 服务购买
			{ name: t('nav-4') }, // 关于我们
		]
	}, [t])

	// pc 端点击
	const handleNav = (item, index) => {
		if (item.ref) {
			// 找到锚点
			let anchorElement = document.getElementById(item.ref)
			if (anchorElement) {
				anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' })
			}
			setCurrentDom(index)
		} else {
			let lang = ''
			if (currentLanguage === 'zh') {
				lang = 'zh-CN'
			} else {
				lang = 'en'
			}
			let url = {
				4: `https://tongfudun.com/?lng=${lang}`, // 关于我们 跳转url
				5: isApp()
					? `https://mcloud.tongfudun.com` // 数信云 跳转url
					: `https://cloud.tongfudun.com`,
			}
			window.open(url[index])
		}
	}

	// 监听页面滚动
	const listenScroll = arr => {
		for (let i = 0; i < arr.length; i++) {
			if (!i) {
				setCurrentDom(-1)
			}
			if (arr[i]) {
				setCurrentDom(i + 1)
			}
		}
	}

	useEffect(() => {
		listenScroll(scrollRef)
	}, [scrollRef])

	return (
		<header className="home-header">
			<h1
				className="logo-wrap"
				onClick={() => {
					handleNav({ ref: 'focus' }, 0)
				}}
			>
				<img src={currentLanguage === 'en' ? logoEn : logoZh} alt="logo" />
			</h1>

			<div className="nav-wrap">
				{/* 1-4 */}
				<ul className={currentLanguage}>
					{navList.map((item, index) => {
						return (
							item.name && (
								<li
									onClick={() => {
										handleNav(item, index)
									}}
									key={index}
									className={currentDom === index ? 'active' : ''}
								>
									{item.name}
								</li>
							)
						)
					})}
				</ul>
				<figure className="change-language" onClick={handlerChange}>
					<img src={currentLanguage === 'en' ? enIcon : zhIcon} alt="" />
				</figure>
				<div
					className="cloud-btn"
					onClick={() => {
						handleNav({ ref: '' }, 5)
					}}
				>
					{t('nav-6')}
				</div>
				<div
					className="m-action"
					onClick={() => {
						handleShowAction()
					}}
				></div>
				<div
					className="download-btn"
					onClick={() => {
						handleNav({ ref: 'download' }, 6)
					}}
				>
					{t('nav-5')}
				</div>
			</div>
		</header>
	)
}
export default Header
