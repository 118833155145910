import React, { useState } from 'react'
import './loading.css'
function LoadingView() {
	const [skList] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])
	return (
		<div className="loading-view">
			<div className="spin-loading-wrap">
				{skList.map((item, i) => {
					return <div key={i} className={`sk-child sk-circle${i}`}></div>
				})}
			</div>
		</div>
	)
}
export default LoadingView
