/* 功能介绍 */
import React, { useState, useEffect, useCallback } from 'react'
import styles from './index.module.css'

import icon1 from '../../images/builder-icon-1.png'
import icon2 from '../../images/builder-icon-2.png'
import icon3 from '../../images/builder-icon-3.png'
import shield from '../../images/shield.png'

import didZh1 from '../../images/didZh1.png'
import didZh2 from '../../images/didZh2.png'
import didZh3 from '../../images/didZh3.png'

import didEn1 from '../../images/didEn1.png'
import didEn2 from '../../images/didEn2.png'
import didEn3 from '../../images/didEn3.png'

import meetZh1 from '../../images/meet-zh-1.png'
import meetZh2 from '../../images/meet-zh-2.png'
import meetZh3 from '../../images/meet-zh-3.png'
import meetZh4 from '../../images/meet-zh-4.png'

import meetEn1 from '../../images/meet-en-1.png'
import meetEn2 from '../../images/meet-en-2.png'
import meetEn3 from '../../images/meet-en-3.png'
import meetEn4 from '../../images/meet-en-4.png'

import coordination from '../../images/coordination.png'

import '../../i18n'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'

import { getUAType } from '../../util'
let UA = getUAType()
const list = [icon3, icon1, icon2]

function Features({ currentLanguage, domRefs, domInViews }) {
	let { solutionRef, featuresRef, safeRef, meetRef, didRef } = domRefs
	let { solutionRefInView, featuresRefInView, safeRefInView, meetRefInView, didRefInView } =
		domInViews

	const { t } = useTranslation()
	//DID钱包说明图
	const [didImgs, setDidImgs] = useState([didZh1, didZh2, didZh3])

	//密会说明图
	const [meetImgs, setMeetImgs] = useState([meetZh1, meetZh2, meetZh3, meetZh4])
	//DID钱包
	const [messageDid, setMessageDid] = useState([1, 0, 0])
	const [currentMessageDid, setCurrentMessageDid] = useState(0)
	//Web3浏览器
	const [meetWeb3Nav, setMeetWeb3Nav] = useState([1, 0, 0])
	//密信导航
	const [messageNav, setMessageNav] = useState([1, 0, 0, 0])
	//密会导航
	const [meetNav, setMeetNav] = useState([1, 0, 0, 0])
	const [currentMeet, setCurrentMeet] = useState(0)

	//DID钱包, Web3浏览器 切换
	const actionNav1 = useCallback(
		(index, type, isSwitch) => {
			switch (type) {
				case 'message':
					let messageList = messageDid.map((el, i) => {
						return isSwitch ? (i === index ? (el ? 0 : 1) : el) : i === index ? 1 : 0
					})
					setMessageDid(messageList)
					setCurrentMessageDid(index)
					break
				case 'meet':
					let meetList = meetWeb3Nav.map((el, i) => {
						return isSwitch ? (i === index ? (el ? 0 : 1) : el) : i === index ? 1 : 0
					})
					setMeetWeb3Nav(meetList)
					break
				default:
					break
			}
		},
		[meetWeb3Nav, messageDid]
	)
	// 密信，密会 切换
	const actionNav = useCallback(
		(index, type, isSwitch) => {
			switch (type) {
				case 'message':
					let messageList = messageNav.map((el, i) => {
						return isSwitch ? (i === index ? (el ? 0 : 1) : el) : i === index ? 1 : 0
					})
					setMessageNav(messageList)
					break
				case 'meet':
					let meetList = meetNav.map((el, i) => {
						return isSwitch ? (i === index ? (el ? 0 : 1) : el) : i === index ? 1 : 0
					})
					setMeetNav(meetList)
					setCurrentMeet(index)
					break
				default:
					break
			}
		},
		[meetNav, messageNav]
	)

	useEffect(() => {
		let didImg = currentLanguage === 'zh' ? [didZh1, didZh2, didZh3] : [didEn1, didEn2, didEn3]
		setDidImgs(didImg)
		let meetImg =
			currentLanguage === 'zh'
				? [meetZh1, meetZh2, meetZh3, meetZh4]
				: [meetEn1, meetEn2, meetEn3, meetEn4]
		setMeetImgs(meetImg)
	}, [currentLanguage])

	return (
		<>
			{/* 企业协同工具 */}
			<section className={styles['enterprise-wrap']} id="solution">
				<h2
					ref={solutionRef}
					className={`public-title ${currentLanguage}`}
					dangerouslySetInnerHTML={{ __html: t('enterprise-title') }}
				></h2>

				<div className={styles['enterprise-content']} in={solutionRefInView.toString()}>
					<p>{t('enterprise-content')}</p>
				</div>
				<div className={styles['enterprise-box']}>
					<div className={styles['enterprise-item']}>
						<div className={styles['enterprise-item-top']}>
							<span>/</span>
							<span>01</span>
							<span className={styles['enterprise-item-img1']}></span>
						</div>
						<div className={styles['enterprise-mid']}>{t('enterprise-item1-mid')}</div>
						<div className={styles['enterprise-bot']}>{t('enterprise-item1-bot')}</div>
					</div>
					<div className={styles['enterprise-item']}>
						<div className={styles['enterprise-item-top']}>
							<span>/</span>
							<span>02</span>
							<span className={styles['enterprise-item-img2']}></span>
						</div>
						<div className={styles['enterprise-mid']}>{t('enterprise-item2-mid')}</div>
						<div className={styles['enterprise-bot']}>{t('enterprise-item2-bot')}</div>
					</div>
					<div className={styles['enterprise-item']}>
						<div className={styles['enterprise-item-top']}>
							<span>/</span>
							<span>03</span>
							<span className={styles['enterprise-item-img3']}></span>
						</div>
						<div className={styles['enterprise-mid']}>{t('enterprise-item3-mid')}</div>
						<div className={styles['enterprise-bot']}>{t('enterprise-item3-bot')}</div>
					</div>
				</div>
			</section>
			{/* 协同平台 */}
			<section className={styles['coordination']}>
				{/* pc端 ipad 横屏 */}
				<div className={styles['coordination-display-pc']}>
					<div className={styles[`coordination-${currentLanguage}`]}></div>
				</div>
				{/* 移动端 ipad 竖屏 */}
				<figure className={styles['coordination-display-ph']}>
					<img src={coordination} alt="" />
					<h2 className={styles['title']}>
						<span>{t('coordination1')}</span>
						<span>{t('coordination2')}</span>
						<span>{t('coordination3')}</span>
					</h2>
					<ul className={styles['content']}>
						<div>{t('coordination-title1')}</div>
						<li>{t('coordination-title1-detail1')}</li>
						<li>{t('coordination-title1-detail2')}</li>
					</ul>
					<ul className={styles['content']}>
						<div>{t('coordination-title2')}</div>
						<li>{t('coordination-title2-detail1')}</li>
						<li>{t('coordination-title2-detail2')}</li>
					</ul>
					<ul className={styles['content']}>
						<div>{t('coordination-title3')}</div>
						<li>{t('coordination-title3-detail1')}</li>
						<li>{t('coordination-title3-detail2')}</li>
					</ul>
					<ul className={styles['content']}>
						<div>{t('coordination-title4')}</div>
						<li>{t('coordination-title4-detail1')}</li>
						<li>{t('coordination-title4-detail2')}</li>
						<li>{t('coordination-title4-detail3')}</li>
					</ul>
					<ul className={styles['content']}>
						<div>{t('coordination-title5')}</div>
						<li>{t('coordination-title5-detail1')}</li>
						<li>{t('coordination-title5-detail2')}</li>
						<li>{t('coordination-title5-detail3')}</li>
						<li>{t('coordination-title5-detail4')}</li>
					</ul>
				</figure>
			</section>
			{/* 协同，分享，建设 */}
			<section className={styles['faction-wrap']} ref={featuresRef} id="features">
				<CSSTransition in={featuresRefInView} timeout={1000} classNames="fade-bottom">
					<h2
						className={`public-title ${currentLanguage}`}
						dangerouslySetInnerHTML={{
							__html: ['pc'].includes(UA) ? t('features-title-1') : t('features-title-1-0'),
						}}
					></h2>
				</CSSTransition>

				<div className={styles['content']}>
					{[1, 2, 3].map((item, index) => {
						return (
							<div className={styles['features-item']} key={index} dom-index={`dom-${index}`}>
								<CSSTransition in={featuresRefInView} timeout={1000} classNames="fade-bottom">
									<figure>
										<img src={list[index]} alt="" dom-index={`dom-${index}`} />
									</figure>
								</CSSTransition>
								<CSSTransition in={featuresRefInView} timeout={1500} classNames="fade-bottom">
									<div className={styles['detail']}>
										<h4>{t(`features-subtitle-${item}`)}</h4>
										<p>{t(`features-detail-${item}`)}</p>
									</div>
								</CSSTransition>
							</div>
						)
					})}
				</div>
			</section>
			{/* 技术特性 */}
			<section className={styles['safe-wrap']} ref={safeRef}>
				<CSSTransition in={safeRefInView} timeout={1000} classNames="fade-bottom">
					<h2 className={`public-title ${currentLanguage}`}>
						<span dangerouslySetInnerHTML={{ __html: t('features-title-2-1') }}></span>
					</h2>
				</CSSTransition>
				<CSSTransition in={safeRefInView} timeout={1500} classNames="fade-bottom">
					<div className={styles['safe-content']}>
						<div className={styles['safe-part-image']}>
							<img src={shield} alt="" className={styles['shield']} />
						</div>
						<div className={styles['safe-part-text']}>
							<p dangerouslySetInnerHTML={{ __html: t('features-safe-1') }}></p>
							<b className={styles['safe-description']}>{t('features-description-1')}</b>
							<p dangerouslySetInnerHTML={{ __html: t('features-safe-2') }}></p>
							<b className={styles['safe-description']}>{t('features-description-2')}</b>
							<p dangerouslySetInnerHTML={{ __html: t('features-safe-3') }}></p>
							<b className={styles['safe-description']}>{t('features-description-3')}</b>
						</div>
					</div>
				</CSSTransition>
			</section>
			{/* 数字协同 */}
			<section
				className={`${styles['meet-wrap']} ${
					currentLanguage === 'en' ? styles['en-meet-wrap'] : ''
				}`}
				id="Meeting"
				ref={meetRef}
			>
				<CSSTransition in={meetRefInView} timeout={1000} classNames="fade-bottom">
					<h2 className={`public-title public-title1 ${currentLanguage} mar`}>
						<span>{t('features-meet-title1')}</span>
						<span>{t('features-meet-title2')}</span>
					</h2>
				</CSSTransition>
				{/* pc端 ipad 横屏 */}
				<CSSTransition in={meetRefInView} timeout={1500} classNames="fade-bottom">
					<section className={styles['meet-container']}>
						<div className={styles['meet-content']}>
							<ul style={{ top: `${-currentMeet * 480}px` }}>
								{meetNav.map((item, index) => {
									return (
										<li key={index} className={item ? styles['active'] : ''}>
											<p>{t(`meet-detail-${index + 1}`)}</p>
											<figure className={styles['detail-wrap']}>
												<img src={meetImgs[index]} alt="" />
											</figure>
										</li>
									)
								})}
							</ul>
						</div>
						<div className={`${styles['meet-nav']}`}>
							<ul>
								{meetNav.map((item, index) => {
									return (
										<li
											key={index}
											className={item ? styles['active'] : ''}
											onClick={() => {
												actionNav(index, 'meet')
											}}
										>
											{t(`meet-nav-${index + 1}`)}
										</li>
									)
								})}
							</ul>
						</div>
					</section>
				</CSSTransition>
				{/* 移动端 ipad 竖屏 */}
				<div className={styles['small-meet-wrap']}>
					<ul className={styles['small-wrap']}>
						{meetNav.map((item, index) => {
							return (
								<li key={index} className={item ? styles['active'] : ''}>
									<div
										className={styles['title']}
										onClick={() => {
											actionNav(index, 'meet', 'switch')
										}}
									>
										<h5> {t(`meet-nav-${index + 1}`)}</h5>
										<span className={styles['action-btn']}></span>
									</div>
									<figcaption className={styles['detail-wrap']}>
										<figure>
											<img src={meetImgs[index]} alt="" />
										</figure>
										<p className={styles['detail']}>{t(`meet-detail-${index + 1}`)}</p>
									</figcaption>
								</li>
							)
						})}
					</ul>
				</div>
			</section>
			{/* DID钱包 */}
			<section
				className={`${styles['message-wrap']} ${
					currentLanguage === 'en' ? styles['en-message-wrap'] : ''
				}`}
				id="Did"
				ref={didRef}
			>
				<CSSTransition in={didRefInView} timeout={1000} classNames="fade-bottom">
					<h2 className={`public-title ${currentLanguage}`}>
						<span dangerouslySetInnerHTML={{ __html: t('features-did-title1') }}></span>
					</h2>
				</CSSTransition>
				{/* pc端 ipad 横屏 */}
				<CSSTransition in={didRefInView} timeout={1500} classNames="fade-bottom">
					<div className={styles['message-container']}>
						<div className={styles['message-nav']}>
							<ul>
								{messageDid.map((item, index) => {
									return (
										<li
											key={index}
											className={item ? styles['active'] : ''}
											onClick={() => {
												actionNav1(index, 'message')
											}}
										>
											{t(`message-did-${index + 1}`)}
										</li>
									)
								})}
							</ul>
						</div>
						<div className={styles['message-content']}>
							<ul style={{ top: `${-currentMessageDid * 480}px` }}>
								{messageDid.map((item, index) => {
									return (
										<li key={index} className={item ? styles['active'] : ''}>
											<figure className={styles['detail-wrap']}>
												<img src={didImgs[index]} alt="" />
											</figure>
											<p>{t(`did-detail-${index + 1}`)}</p>
										</li>
									)
								})}
							</ul>
						</div>
					</div>
				</CSSTransition>
				{/* 移动端 ipad 竖屏 */}
				<div className={styles['small-message-wrap']}>
					<ul className={styles['small-wrap']}>
						{messageDid.map((item, index) => {
							return (
								<li key={index} className={item ? styles['active'] : ''}>
									<div
										className={styles['title']}
										onClick={() => {
											actionNav1(index, 'message', 'switch')
										}}
									>
										<h5> {t(`message-did-${index + 1}`)}</h5>
										<span className={styles['action-btn']}></span>
									</div>
									<figcaption className={styles['detail-wrap']}>
										<figure>
											<img src={didImgs[index]} alt="" />
										</figure>
										<p className={styles['detail']}>{t(`did-detail-${index + 1}`)}</p>
									</figcaption>
								</li>
							)
						})}
					</ul>
				</div>
			</section>
		</>
	)
}
export default Features
