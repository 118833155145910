export const FooterI18n = {
	zh: {
		copyright:
			'Powered by PayEgis 法律声明 Copyright ©2011-2023 通付盾. All Rights Reserved.',
		'footer-title-1': '解决方案',
		'footer-title-2': '功能介绍',
		'footer-title-3': '关于我们',
		'footer-1-1': '任务协同工具',
		'footer-1-2': 'Web3 DAO Kit',
		'footer-2-1': '数据托管',
		'footer-2-2': '开放平台',
		'footer-2-3': '链上协同',
		'footer-3-1': '关于通付盾：',
		'footer-3-2': '商务咨询：',
		'footer-3-3': '售后服务：',
		'footer-3-4': '客户服务：',
	},
	en: {
		copyright: 'Copyright © 2011-2023 PayEgis. All Rights Reserved.',
		'footer-title-1': 'Solution',
		'footer-title-2': 'Features',
		'footer-title-3': 'About us',
		'footer-1-1': 'Task Collaboration Tool',
		'footer-1-2': 'Web3 DAO Kit',
		'footer-2-1': 'Data Hosting',
		'footer-2-2': 'Open Platform',
		'footer-2-3': 'DAO Kit',
		'footer-3-1': 'About PayEgis：',
		'footer-3-2': 'Business consultation：',
		'footer-3-3': 'After-sales service：',
		'footer-3-4': 'Customer Service：',
	},
}
