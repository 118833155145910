import { EventEmitter } from 'events'

const UA = window.navigator.userAgent
const LocationSearch = window.location.search

//2.创建事件总线
export const eventBus = new EventEmitter()

/**
 * 当前是 wx|app|m|ipad|pc
 **/
export const getUAType = () => {
	let isSafari = UA.indexOf("Safari") !== -1 && UA.indexOf("Version") !== -1;
	let isIphone = UA.indexOf("iPhone") !== -1 && UA.indexOf("Version") !== -1
	if (/phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone/i.test(
		UA
	)) {
		return 'm'
	} else if (isSafari && !isIphone && 'ontouchend' in document) {
		return 'ipad'
	} else {
		return 'pc'
	}
}
export const isApp = () => {
	return UA.indexOf('ChainMeet') > -1 || UA.indexOf('ChainPal') > -1
}

/**
 * @desc 获取路径参数
 */
export const getQueryValue = (queryName) => {
	let query = decodeURI(LocationSearch.substring(1));
	let vars = query.split("&");
	for (let i = 0;i < vars.length;i++) {
		let pair = vars[i].split("=");
		if (pair[0] === queryName) {
			return pair[1];
		}
	}
	return null;
}