/* 焦点内容 */

import React from 'react'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'

import './index.css'
import '../../i18n'
import focusImage from '../../images/focus.png'
function FocusContent({ focusRefInView, currentLanguage }) {
	const { t } = useTranslation()
	return (
		<section className="focus">
			<div className="content">
				<CSSTransition in={focusRefInView} timeout={1000} classNames="fade-left">
					<div className={`text-wrap ${currentLanguage}`}>
						<h2>{t('appName')}</h2>
						<h3 className="sub-title">{t('focus-title')}</h3>
						<p>{t('focus-content')}</p>
						<span
							className="download-btn"
							onClick={() => {
								let anchorElement = document.getElementById('download')
								if (anchorElement) {
									anchorElement.scrollIntoView({
										block: 'start',
										behavior: 'smooth',
									})
								}
							}}
						>
							{t('nav-5')}
						</span>
					</div>
				</CSSTransition>
				<CSSTransition in={focusRefInView} timeout={1000} classNames="fade-into">
					<div className="focus-image">
						<img src={focusImage} alt="chainpal" />
					</div>
				</CSSTransition>
			</div>
		</section>
	)
}
export default FocusContent
