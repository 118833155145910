/* 服务购买 - 会员信息*/
import React, { useMemo } from 'react'
import styles from './index.module.css'
import '../../i18n'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
function Service({ serviceRefInView, currentLanguage }) {
	const { t } = useTranslation()
	const list = useMemo(() => {
		return [
			{
				level: t('service-vip-0'),
				price: 0,
				tip: t('service-vip-tip'),
				list: [
					{
						name: t('service-list-0'),
						subList: [t('service-list-0-1-1'), t('service-list-0-1-2')],
					},
					{
						name: t('service-list-1'),
						subList: [
							t('service-list-0-2-0'),
							t('service-list-0-2-1'),
							t('service-list-0-2-2'),
							t('service-list-0-2-3'),
							t('service-list-0-2-4'),
							t('service-list-0-2-5'),
						],
					},
				],
			},
			{
				level: t('service-vip-1'),
				price: 90,
				tip: t('service-vip-tip-1'),
				list: [
					{
						name: t('service-list-0'),
						subList: [t('service-list-1-1-1')],
					},
					{
						name: t('service-list-1'),
						subList: [t('service-list-1-2-0'), t('service-list-1-2-2'), t('service-list-1-2-3')],
					},
				],
			},
			{
				level: t('service-vip-2'),
				price: 180,
				tip: t('service-vip-tip-1'),
				list: [
					{
						name: t('service-list-0'),
						subList: [t('service-list-2-1-1')],
					},
					{
						name: t('service-list-1'),
						subList: [t('service-list-1-2-0'), t('service-list-2-2-2'), t('service-list-1-2-3')],
					},
					{
						name: t('service-list-2'),
						subList: [
							// t('service-list-2-3-1'), // 跨域专网暂时隐藏
							t('service-list-2-3-2'),
							t('service-list-2-3-3'),
							t('service-list-2-3-4'),
						],
					},
				],
			},
			{
				level: t('service-vip-3'),
				price: 18000,
				tip: t('service-vip-tip-1'),
				list: [
					{
						name: t('service-list-0'),
						subList: [t('service-list-2-1-1')],
					},
					{
						name: t('service-list-1'),
						subList: [t('service-list-1-2-0'), t('service-list-3-2-2'), t('service-list-1-2-3')],
					},
					{
						name: t('service-list-2'),
						subList: [
							// t('service-list-2-3-1'),// 跨域专网暂时隐藏
							t('service-list-3-3-5'),
							t('service-list-3-3-6'),
							t('service-list-2-3-2'),
							t('service-list-2-3-3'),
							t('service-list-2-3-4'),
						],
					},
				],
			},
		]
	}, [t])
	return (
		<div className={styles['service-container']}>
			<CSSTransition in={serviceRefInView} timeout={1000} classNames="fade-bottom">
				<h2 className={`public-title ${currentLanguage}`}>
					<strong dangerouslySetInnerHTML={{ __html: t('service-title') }}></strong>
				</h2>
			</CSSTransition>
			<CSSTransition in={serviceRefInView} timeout={1500} classNames="fade-bottom">
				<section className={styles['service-wrap']}>
					<ul>
						{list.map((item, index) => {
							return (
								<li key={index}>
									<h5
										className={styles['level-name']}
										dangerouslySetInnerHTML={{ __html: item.level }}
									></h5>
									<p className={styles['price']}>
										<em>￥</em>
										{item.price}
										{item.price ? <span>/{t('service-vip-unit')}</span> : ''}
									</p>
									<p className={styles['voucher']}>
										<span>
											{index === 2
												? `(${t('service-vip-tip-4')})`
												: index === 3
												? t('service-vip-tip-3')
												: ''}
										</span>
									</p>
									<p className={styles['tip']}>{item.tip}</p>
									{item.list.map((ele, idx) => {
										return (
											<div key={`${index}-${idx}`} className={styles['info']}>
												<strong>{ele.name}</strong>
												{ele.subList.map((e, i) => {
													return (
														<p
															key={`${index}-${idx}-${i}`}
															dangerouslySetInnerHTML={{ __html: e }}
														></p>
													)
												})}
											</div>
										)
									})}
								</li>
							)
						})}
					</ul>
					<p className={styles['service-tip']}>
						<span>{t('service-vip-tip-2')}</span>
					</p>
				</section>
			</CSSTransition>
		</div>
	)
}
export default Service
