import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { useTranslation } from 'react-i18next'
import './home.css'
import Header from '../components/header'
import FocusContent from '../components/focus'
import FeaturesContent from '../components/features'
import ServiceContent from '../components/service'
import Download from '../components/download'
import FooterContent from '../components/footer'
import LoadingView from '../components/loading/LoadingView'
import { getQueryValue, isApp } from '../util'

async function loadPolyfills() {
	if (typeof window.IntersectionObserver === 'undefined') {
		await import('intersection-observer')
	}
}
loadPolyfills()

export default function App() {
	const { t, i18n } = useTranslation()
	const [showPage, setShowPage] = useState(true)
	// 当前语言
	const [currentLanguage, setLanguage] = useState(
		(getQueryValue('lang') ||
			window.localStorage.getItem('ChainPal_language')) === 'en'
			? 'en'
			: 'zh'
	)
	// m 导航弹窗是否显示
	const [showAction, setShowAction] = useState(false)
	const [mNav] = useState(['solution', 'features', 'service', ''])

	// 各个模块
	const [focusRef, focusRefInView] = useInView({ threshold: 0 })
	const [featuresRef, featuresRefInView] = useInView({ threshold: 0 })
	const [serviceRef, serviceRefInView] = useInView({ threshold: 0 })
	const [downloadRef, downloadRefInView] = useInView({ threshold: 0 })
	const [solutionRef, solutionRefInView] = useInView({ threshold: 0 })

	const [safeRef, safeRefInView] = useInView({ threshold: 0 })
	const [messageRef, messageRefInView] = useInView({ threshold: 0 })
	const [meetRef, meetRefInView] = useInView({ threshold: 0 })
	const [didRef, didRefInView] = useInView({ threshold: 0 })
	// 点击切换语音
	const handlerChange = useCallback(() => {
		let lang = i18n.language === 'zh' ? 'en' : 'zh'
		window.localStorage.setItem('ChainPal_language', lang)
		i18n.changeLanguage(lang)
		setLanguage(lang)
		window.history.replaceState(null, null, `?lang=${lang}`)
	}, [i18n])

	// m 端点击
	const handleShowAction = useCallback(() => {
		setShowAction(!showAction)
	}, [showAction])
	// m 端切换导航
	const changeNav = (item) => {
		handleShowAction()
		if (item) {
			// 找到锚点
			let anchorElement = document.getElementById(item)
			if (anchorElement) {
				anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' })
			}
		} else {
			// 关于我们 => url 跳转
			let lang = currentLanguage === 'zh' ? 'zh-CN' : 'en'
			window.open(isApp() ? `https://tongfudun.com` : `https://tongfudun.com/?lng=${lang}`)
		}
	}
	//m 端 导航弹窗
	const ModalView = () => {
		return (
			<div className="modal-wrap">
				<div
					className="mask"
					onClick={() => {
						handleShowAction()
					}}
				></div>
				<div className="modal-content">
					<span
						className="close-btn"
						onClick={() => {
							handleShowAction()
						}}
					></span>
					{mNav.map((item, index) => {
						return (
							<div
								className="m-nav"
								key={index}
								onClick={() => {
									changeNav(item)
								}}
							>
								{t(`nav-${index + 1}`)}
							</div>
						)
					})}
				</div>
			</div>
		)
	}
	// 切换语言
	const setMetaContent = useCallback(() => {
		let metas = document.getElementsByTagName('meta')
		for (let i = 0;i < metas.length;i++) {
			// 详情
			if (metas[i]['name'] === 'description') {
				metas[i]['content'] = t('metaDescription')
			} else if (metas[i]['name'] === 'keywords') {
				metas[i]['content'] = t('metaKeyWords')
			}
		}
		document.title = t('metaTitle')
	}, [t])

	// 拼接信息
	const setDescOrShareStr = useMemo(() => {
		return () => {
			let shareStr = {
				title: t('metaTitle'),
				desc: t('metaDescription'),
				icon: 'https://chainpal.tongfudun.com/favicon.ico',
			}
			console.log(shareStr)
			return window.btoa(
				unescape(window.encodeURIComponent(JSON.stringify(shareStr)))
			)
		}
	}, [t])
	// 获取信息

	useEffect(() => {
		if (isApp()) {
			let shareStr = setDescOrShareStr()
			let reg = new RegExp('chainmeetShare=[^&]*', 'gi')
			let url =
				window.location.search.indexOf('chainmeetShare') > -1
					? window.location.search.replace(reg, `chainmeetShare=${shareStr}`)
					: `${window.location.search}${!window.location.search ? '?' : '&'
					}chainmeetShare=${shareStr}`

			window.history.replaceState(null, null, url)
		}
	}, [setDescOrShareStr])

	useEffect(() => {
		i18n.changeLanguage(currentLanguage)
		setLanguage(currentLanguage)
	}, [currentLanguage, i18n])

	useEffect(() => {
		setMetaContent()
		return () => {
			setMetaContent()
		}
	}, [setMetaContent])

	useEffect(() => {
		let timer = setTimeout(() => {
			setShowPage(false)
			timer && clearTimeout(timer)
		}, 1800)
		return () => {
			clearTimeout(timer)
		}
	}, [])

	return (
		<>
			<div
				className={`container ${showAction ? 'm-filter' : ''} ${showPage ? 'container-loading' : ''}`}
			>
				{/* 头部 */}
				<Header
					props={{
						scrollRef: [
							solutionRefInView,
							featuresRefInView,
							serviceRefInView
						],
						currentLanguage,
						handlerChange,
						showAction,
						handleShowAction,
					}}
				></Header>
				{/* 主内容 */}
				<main className="main" id="main">
					{/* 焦点 */}
					<div className="focus-content" id="focus" ref={focusRef}>
						<FocusContent
							currentLanguage={currentLanguage}
							focusRefInView={focusRefInView}
						></FocusContent>
					</div>
					{/* 功能介绍 */}
					<div className="features-content">
						<FeaturesContent
							domRefs={{
								solutionRef,
								featuresRef,
								safeRef,
								messageRef,
								meetRef,
								didRef
							}}
							domInViews={{
								solutionRefInView,
								featuresRefInView,
								safeRefInView,
								messageRefInView,
								meetRefInView,
								didRefInView,
							}}
							currentLanguage={currentLanguage}
						></FeaturesContent>
					</div>
					{/* 服务购买 */}
					<div className="service-content" id="service" ref={serviceRef}>
						<ServiceContent
							serviceRefInView={serviceRefInView}
							currentLanguage={currentLanguage}
						></ServiceContent>
					</div>

					{/* 下载中心 */}
					<div className="download-content" id="download" ref={downloadRef}>
						<Download
							downloadRefInView={downloadRefInView}
							currentLanguage={currentLanguage}
						></Download>
					</div>
				</main >
				{/* 底部 */}
				<FooterContent currentLanguage={currentLanguage} ></FooterContent>
				{showAction && <ModalView></ModalView>
				}
			</div >
			{/* loading */}
			{showPage && <LoadingView></LoadingView>}
		</>
	)
}
